import React from 'react';
import {
  Box,
  Image,
  Heading,
  Text,
  Icon,
  Divider,
  Flex,
  Link,
  Grid,
  Input,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';

import { FooterLogo } from '../../assets/exports';
import { company, contact, icons } from '../common/constants';
import { FooterGrid } from '../common/FooterGrid';

const Footer = () => {
  const dates = new Date();
  const year = dates.getFullYear();

  const [isMobile] = useMediaQuery('(max-width: 991px)');

  return (
    <Box p={['38px', '38px', '50px', '50px']} bg="#191A23" color="#fff">
      <Flex
        justifyContent="space-between"
        flexDir={{ base: 'column', md: 'row' }}
        alignItems="center"
        gap="10px"
        mb={{ base: '28px', md: '66px' }}
      >
        <Image mx={{ base: 'auto', md: 'unset' }} src={FooterLogo} w="113px" />
        <Flex
          gap="10px"
          flexDir={{ base: 'column', md: 'row' }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          {company?.map((data, i) => (
            <Link key={i} pb="5px" href={data.link} target="_blank">
              {data.name}
            </Link>
          ))}
        </Flex>
        <Box gap="14px" display={{ base: 'none', md: 'flex' }}>
          {icons?.map((data, i) => (
            <Grid
              placeItems="center"
              key={i}
              flexDir="column"
              justifyContent="center"
              rounded="full"
              align="center"
              w="40px"
              h="40px"
              bg="#fff"
              color="#191A23"
            >
              <Link href={data.link} target="_blank">
                <Image w="24px" h="24px" as={data?.icon} />
              </Link>
            </Grid>
          ))}
        </Box>
      </Flex>
      <Grid gap="2rem" gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}>
        <Box>
          <FooterGrid title="Contact Us:" data={contact} />
        </Box>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          borderRadius="14px"
          bg="#292A32"
          gap="20px"
          pt={{ base: '23px', md: '56px' }}
          pb={{ base: '30px', md: '64px' }}
          px={['30px', '30px', '30px', '40px']}
        >
          <Input
            flex={{ base: '', md: '1' }}
            bg="transparent"
            h="56px"
            placeholder="Email"
            border="1px solid #DADADA"
          />
          <Button h="56px" flex={{ base: '', md: '1' }}>
            Subscribe to newsletter
          </Button>
        </Flex>
      </Grid>
      <Box
        pt="28px"
        justifyContent="center"
        gap="14px"
        display={{ base: 'flex', md: 'none' }}
      >
        {icons?.map((data, i) => (
          <Flex
            key={i}
            flexDir="column"
            justifyContent="center"
            rounded="full"
            align="center"
            bg="#fff"
            color="#191A23"
            w="40px"
            h="40px"
          >
            <Link href={data.link} target="_blank">
              <Image w="24px" h="24px" as={data?.icon} />
            </Link>
          </Flex>
        ))}
      </Box>
      <Flex w="100%" pt="28px">
        <Flex gap={5} flexDir="column" align="center" w="100%">
          <Divider w="100%" />

          <Text
            w="100%"
            pt="28px"
            textAlign={{ base: 'center', md: 'left' }}
            fontSize="13px"
          >
            {year} Fastamoni Technologies inc.. All Rights Reserved.
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
