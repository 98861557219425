import { BiUserCircle } from "react-icons/bi";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaUsersCog,
} from "react-icons/fa";
import { RiSecurePaymentFill, RiShieldCheckFill } from "react-icons/ri";
import { HiCubeTransparent } from "react-icons/hi";
import {
  Av1,
  Av2,
  Av3,
  Av4,
  Av5,
  aws,
  brainImg,
  bspLogo,
  Calling,
  Chat,
  cloudLogo,
  effortImg,
  facebook,
  flutterwaveLogo,
  instagramIcon,
  lenco,
  linkedinIcon,
  manageIcon,
  Message,
  ngn,
  stack,
  twitter,
  usa,
} from "../../assets/exports";
import { BsPiggyBankFill } from "react-icons/bs";
import { RiTwitterXLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";

import { RiFacebookFill } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa6";

export const header = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "Products",
    sub: [
      {
        id: 1,
        name: "Edupali",
        path: "https://www.edupali.com",
      },
      {
        id: 2,
        name: "Bizflex",
        path: "https://www.bizflex.africa/",
      },
    ],
  },
  {
    id: 3,
    name: "About",
    path: "/about",
  },
  {
    id: 4,
    name: "Contact",
    path: "/contact",
  },
  // {
  //   id: 5,
  //   name: "Blog",
  //   path: "/blog",
  // },
  {
    id: 6,
    name: "FAQs",
    path: "/faq",
  },
];

export const activeStyle = {
  color: "#7B47CC",
  fontSize: "16px",
  fontWeight: "500",
  paddingLeft: "20px",
};

export const resources = [
  {
    id: 1,
    name: "Articles",
  },
];

export const contact = [
  {
    id: 1,
    name: "Email: support@fastamoni.com",
    path: "support@fastamoni.com",
  },
  {
    id: 2,
    name: "Phone: 02012296204",
    path: "tel: +2348100121160",
  },
  {
    id: 3,
    name: "Address: 4th Floor, Polystar Tower, 128, Remi Olowude Street, Lekki Phase 1, Lagos",
  },
];

export const company = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About us",
    path: "/about-us",
  },
  {
    id: 3,
    name: "Products",
    path: "/products",
  },
  {
    id: 4,
    name: "Contact Us",
    path: "contact",
  },
];

export const address = [
  {
    id: 1,
    flag: usa,
    location: "DE, USA",
    add: "1207 Delaware Ave #162 Wilmington, DE 19806",
  },
  {
    id: 1,
    flag: ngn,
    location: "Lagos, Nigeria",
    add: "3rd Floor, Top Notch Plaza, Abraham Adesanya, Ajah-Lekki, Lagos, Nigeria",
  },
];

export const icons = [
  {
    id: 1,
    icon: RiFacebookFill,
    link: "https://facebook.com/fastamoni",
  },
  {
    id: 2,
    icon: RiInstagramLine,
    link: "https://www.instagram.com/fastamonihq/",
  },
  {
    id: 3,
    icon: RiTwitterXLine,
    link: "https://twitter.com/fastamoni",
  },
  {
    id: 4,
    icon: FaLinkedinIn,
    link: "https://www.linkedin.com/company/fastamoni/",
  },
];

export const avatars = [Av1, Av2, Av3];

export const avatar = [
  {
    id: 1,
    img: Av4,
    color: "#F1EBF9",
  },
  {
    id: 2,
    img: Av5,
    color: "#FEF8E7",
  },
];

export const experience = [
  {
    id: 1,
    bg: "#FEF8E7",
    title: "Digital Checking Account",
    desc: "This feature allows groups and partners create a jointly managed account to improve transparency and inclusion during payment processing",
    color: "#F3C948",
    icon: BiUserCircle,
  },
  {
    id: 2,
    bg: "#FEF8E7",
    color: "#5855DD",
    desc: "This feature helps businesses manage payment request from their employees and it helps to track how company funds are being spent.",
    title: "Employee Payment Management",
    icon: FaUsersCog,
  },
  {
    id: 3,
    bg: "#F1EBF9",
    color: "#7B47CC",
    desc: "We help you take control of your businesses financial journey by providing added services such as: Business registration, Corporate account creation, Invoice, finance management system for team, POS services and Loan request",
    title: "FastaMoni Business",
    icon: RiShieldCheckFill,
  },
  // {
  //   id: 4,
  //   bg: "#F1EBF9",
  //   img: World,
  //   color: "#7B47CC",
  //   desc: "We have built a solution that allows you make cross-border payment in split seconds with no hidden charges, competitive exchange rate, no complicating process, and lightning fast transaction speed",
  //   title: "Borderless World",
  //   icon: MdCircle,
  // },
];

export const reviews = [
  {
    id: 1,
    desc: "Ever since we signed up with FastaMoni we have had a lot more work done on days we cannot get everyone together in one place. Now, I can make a transfer and my Financial Secretary can also approve it wherever he is. It felt like a funny statement when we were told it's a possibility. Now, it's a reality",
    bg: "#F1EBF9",
    color: "#7B47CC",
    author: "Benjamin Jones Apata",
  },
  {
    id: 2,
    desc: "The headache of processing every single request made by my colleagues became a Burden, so I asked the company to sign up with FastaMoni so we can have a way for my colleagues to request for Money online and everyone can see it in real Time. It was the exact way the Product Ad I saw explained it. Seamless, Easy and Transparent.",
    bg: "#FEF8E7",
    color: "#F3C948",
    author: "Emeka Abraham Ishaya",
  },
  {
    id: 3,
    desc: "We started out using my account details for the collection of our Ajo and it was difficult to do, it got worse when I went out of the country and someone else collected the money and ran off with it. With FastaMoni I was told we can have multiple signatories and we all can see what happens in our account. I am glad it turned out this way. Kudos to the team",
    bg: "#F1EBF9",
    color: "#7B47CC",
    author: "Elizabeth Holmes Olapeju",
  },
  {
    id: 4,
    desc: "So whenever a customer request to make transfer in my supermarket, my sales girl will reject that payment mode when I am not available to confirm alert and most times we lost that sales due to transaction confirmation. With FasatMoni, my sale girl can instantly confirm a transaction with no access to my account. Good thing is she does not need to call me each time someone made a transfer payment. FastaMoni POS feature is the best.",
    bg: "#FEF8E7",
    color: "#F3C948",
    author: "Beatrice Nwaogu Fidelis",
  },
  {
    id: 5,
    desc: "Ever since we signed up with FastaMoni we have had a lot more work done on days we cannot get everyone together in one place. Now, I can make a transfer and my Financial Secretary can also approve it wherever he is. It felt like a funny statement when we were told it's a possibility. Now, it's a reality",
    bg: "#F1EBF9",
    color: "#7B47CC",
    author: "Benjamin Jones Apata",
  },
  {
    id: 6,
    desc: "So whenever a customer request to make transfer in my supermarket, my sales girl will reject that payment mode when I am not available to confirm alert and most times we lost that sales due to transaction confirmation. With FasatMoni, my sale girl can instantly confirm a transaction with no access to my account. Good thing is she does not need to call me each time someone made a transfer payment. FastaMoni POS feature is the best.",
    bg: "#FEF8E7",
    color: "#F3C948",
    author: "Beatrice Nwaogu Fidelis",
  },
];
export const contactOption = [
  {
    id: 1,
    title: "Call Us",
    icon: Calling,
    desc: "Call us to speak to a member of our team.",
    color: "#7B47CC",
    last1: "+1 (302) 566-4192",
    last2: "+234 812 428 789",
    link1: "tel: +1(302)5664192",
    link2: "tel: +234812428789",
  },
  {
    id: 2,
    title: "Support",
    icon: Chat,
    desc: "Get realtime support with our live chat feature.",
    color: "#7B47CC",
    supp: "supp",
    last: "Support Center",
  },
  {
    id: 3,
    title: "Email Us",
    icon: Message,
    desc: "Email us for general queries, including difficulties and any other problem.",
    color: "#7B47CC",
    last: "support@fastamoni.comm",
    link: "mailto:support@fastamoni.com",
  },
];

export const faqs = [
  {
    id: 1,
    ques: "How can I make use of FastaMoni ?",
    ans: "Download our App on Google Play store or App Store, register with a valid email and password you can always remember.",
  },
  {
    id: 2,
    ques: "Do I need to provide BVN and Valid means of ID to open an account ?",
    ans: "Yes according to CBN guidelines, before you can operate an account, you need to provide those documents during KYC process.",
  },
  {
    id: 3,
    ques: "How much does it cost to use FastaMoni ?",
    ans: "Opening a digital checking account for your business or organization on FastaMoni is free. No account maintenance fee, No minimum balance, No hidden charges. You only pay a stipend when you send money to other banks. sending money to a FastaMoni user is free.",
  },
  {
    id: 4,
    ques: "Is my account insured ?",
    ans: "Yes, your account is insured up to the NDIC limit. FastaMoni works with NDIC-insured banks to store your deposits, namely URE Microfinance Bank. Account Number is provided by Providus Bank under license by respective",
  },
];

export const generate = [
  {
    id: 1,
    desc: "Add Multiple Signatories to your group account for accountability",
  },
  {
    id: 2,
    desc: "Signatories monitor and approve transactions with their Mobile App or USSD",
  },
  {
    id: 1,
    desc: "Increased Transparency and inclusion amongst stakeholders during transaction processing",
  },
];

export const privacy = [
  {
    id: 1,
    title: "INTRODUCTION",
    sub: "FastaMoni Technologies Limited",
    text: " (“FastaMoni”, “we” “our” “us”) values your privacy and the security of the information you share with us. This Privacy Policy explains how we collect, use, disclose and safeguard the information we obtain from you in relation to your use of our website, product or services. It applies to our website and all related sites, applications, services, and tools regardless of how you access or use them. \n\n By using our website, products, or services, you consent to the terms and practices contained in this Privacy Policy and you grant us the right to collect and process your data in accordance with the terms of this Policy. \n\n Our website and services are not directed to children under 18. We do not knowingly collect information from children under 18. If as a parent or guardian, you become aware that your child or ward child has provided us with any information without your consent, please contact us through the details provided in this Privacy Policy. ",
  },
  {
    id: 2,
    title: "THE INFORMATION WE COLLECT",
    text: "When you use our website or service, we may collect certain types of information about you including the following:",
    subs: [
      {
        id: 1,
        title: "Information you provide to us",
        text: "This includes but is not limited to your personal data (such as your full name, phone number(s), email address, home or office addresses, product and service preferences, and responses) bank verification number for registration and compliance purposes.",
      },
      {
        id: 2,
        title: "Information we collect automatically",
        text: "This includes but is not limited to your IP address, browser type, mobile device ID, device type, operating system version, connection information, mobile network information, location derived from GPS-enabled services, information based on your usage of the service such as time, date and duration of your use, referral URLs, search terms and search history, camera, contact list, browsing history, purchase history and advertisement interactions.",
      },
      {
        id: 3,
        title: "Information we obtain from 3rd Parties",
        text: "We may retrieve additional personal information about you from third parties and other identification or verification services such as your financial institution and payment processor. With your consent, we may also collect additional Personal Information in other ways including emails, surveys, and other forms of communication. Once you begin using our services through your FastaMoni account we will keep records of your transactions and collect information of your other activities related to our services. We will not share or disclose your Personal Information with a third party without your consent except as may be required for the purpose of providing you with our services or under applicable legislation. \n \n In providing you with the services, we may rely on third-party servers located in foreign jurisdictions from time to time, which as a result, may require the transfer or maintenance of your personally identifiable information on computers or servers in foreign jurisdictions. We will endeavour to ensure that such foreign jurisdictions have data protection legislation that is no less than the existing data protection regulations in force in Nigeria and your personally identifiable information is treated in a safe and secure manner.",
      },
    ],
  },
  {
    id: 3,
    title: "WHY WE COLLECT YOUR INFORMATION",
    text: "We collect your information to ensure your easy and seamless access to our services. We use the information we collect for the following purposes:",
    list: [
      {
        id: 1,
        text: "To enable us to provide you with a personalized experience of our product and services.",
      },
      {
        id: 2,
        text: "To communicate with you and provide you with information on our Services, such marketing content, newsletters and service update. However, we will provide you with an option to unsubscribe if you do not want to hear from us",
      },
      {
        id: 3,
        text: "To provide support services to you.",
      },
      {
        id: 4,
        text: "To process your orders and requests.",
      },
      {
        id: 5,
        text: "To analyse and understand your use of our products and services.",
      },
      {
        id: 6,
        text: "To protect against illegal, malicious, and fraudulent activity.",
      },
      {
        id: 7,
        text: "To analyse and improve the quality of our services and offerings.",
      },
      {
        id: 8,
        text: "To facilitate your interactions with our social media platforms.",
      },
      {
        id: 9,
        text: "To analyse and learn about our users' demographics, interests, and behaviour.",
      },
      {
        id: 10,
        text: "To identify and repair errors and bugs on our platforms.",
      },
      {
        id: 11,
        text: "To facilitate dissemination of information about our services and our partners.",
      },
    ],
  },
  {
    id: 4,
    title: "SHARING YOUR INFORMATION WITH THIRD PARTIES",
    text: "We may sometimes share the information we have collected from you with third parties. These third parties include our affiliates, subsidiaries, partners, third-party vendors and suppliers who render services for us or on our behalf and our professional advisers (such as our auditors, accountants, lawyers, etc.). We may share the information to facilitate the seamless delivery of our services or in compliance with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with local laws.",
  },
  {
    id: 5,
    title: "COOKIES",
    text: "We use cookies to identify you as a User and make your user experience easier, customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities. \n \n Our cookies never store personal or sensitive information; they simply hold a unique random reference to you so that once you visit the site we can recognize who you are and provide certain content to you. \n \n If your browser or browser add-on permits, you have the choice to disable cookies on our website, however this may impact your experience using our website.",
  },
  {
    id: 6,
    title: "GOVERNING PRINCIPLES OF DATA PROCESSING",
    text: "We process your information in accordance with the provisions of the Nigeria Data Protection Regulation and these principles:",
    list: [
      {
        id: 1,
        text: "Your personal data is collected and processed in accordance with specific, legitimate and lawful purpose consented to by you, provided that further processing may be done by archiving the data for public interest, scientific or research purposes, or statistical purposes.",
      },
      {
        id: 2,
        text: "The data collected is adequate, accurate and without prejudice to the dignity of the human person.",
      },
      {
        id: 3,
        text: "The data collected is stored only for the period within which it is reasonably needed.",
      },
      {
        id: 4,
        text: "The data collected is secured against all foreseeable hazards and breaches such as theft, cyberattack, viral attack, dissemination, manipulations of any kind, damage by rain, fire or exposure to other natural elements.",
      },
      {
        id: 5,
        text: "We owe a duty of care to you in respect of the data we have obtained from you.",
      },
    ],
  },
  {
    id: 7,
    title: "HOW WE SAFEGUARD YOUR INFORMATION",
    text: "In addition to the security measures we employ, you must also ensure that you take responsibility for the protection of your personal information. We will not be liable for any loss or damage caused by the activities of hackers and fraudsters when we have taken all necessary and reasonable steps to protect your information in our possession. You are fully responsible for safeguarding your username, password, and financial information.",
  },
  {
    id: 8,
    title: "LINKS TO THIRD PARTY WEBSITES",
    text: "Our website, products and services may contain links to other websites not subject to this Privacy Policy. When you access those websites, they may collect your personal information in accordance with their privacy and data collection policies. We are not responsible for the data collected by these third parties and you are advised to study their privacy policies before you make use of their services.",
  },
  {
    id: 9,
    title: "YOUR PRIVACY RIGHTS",
    text: "By providing us with your personal information, you have certain rights in accordance with the provisions of the Nigeria Data Protection Regulation which include:",
    list: [
      {
        id: 1,
        text: "the right to request for your personal data in our possession.",
      },
      {
        id: 2,
        text: "the right to object to the processing of your personal data.",
      },
      {
        id: 3,
        text: "the right to request rectification and modification of Personal Information which FastaMoni keeps.",
      },
      {
        id: 4,
        text: "the right to request for the deletion of your personal data in our possession. To exercise any of the rights listed here, please contact us through the details provided below. ",
      },
    ],
    desc: "All such requests will be reviewed and considered in accordance with the provisions of the applicable data protection regulations. \n \n You also have the right to opt-out of receiving the informative materials we send to our users by clicking the “Unsubscribe” button found at the bottom of such emails. You also have the right to decline to provide your personal Information when it is requested, however, certain services or all the services may be unavailable to you.",
  },
  {
    id: 10,
    title: "INTERNATIONAL TRANSFER OF YOUR INFORMATION",
    text: "We may need to transfer your information in our possession to third parties outside Nigeria. Such transfers will only be done in accordance with the applicable data protection regulations in such jurisdiction. While undertaking the international transfer of your information, we will put security measures in place to reasonably protect your data during transmission. We shall, among other things, confirm whether the country is on the National Information Technology Development Agency (“NITDA”) White List of Countries with adequate data protection laws.",
  },
  {
    id: 11,
    title: "DATA RETENTION PERIOD",
    text: "We will retain your information for as long as your account is active or as needed to provide our services to you, comply with our legal and statutory obligations or verify your information with a financial institution. \n \n FastaMoni is statutorily obligated to retain the data you provide us with in order to process transactions, ensure settlements, make refunds, identify fraud and in compliance with laws and regulatory guidelines applicable to us, our banking providers and card processors. \n \n Therefore, even after closing your FastaMoni account, we will retain certain Personal Information and transaction data to comply with these obligations. All Personal Information shall be destroyed by FastaMoni where possible. For all Personal Data and records obtained, used and stored by FastaMoni, we shall perform periodical reviews of the data retained to confirm the accuracy, purpose, validity and requirement to retain. \n \n The length of storage of Personal Information shall, amongst other things, be determined by:",
    list: [
      {
        id: 1,
        text: "the contract terms agreed between FastaMoni and the Data Subject or as long as it is needed for the purpose for which it was obtained.",
      },
      {
        id: 2,
        text: "whether the transaction or relationship has statutory implication or a required retention period.",
      },
      {
        id: 3,
        text: "whether there is an express request for deletion of Personal Data by the Data Subject, provided that such request will only be treated where the Data Subject is not under any investigation which may require FastaMoni to retain such Personal Data or there is no subsisting contractual arrangement with the Data Subject that would require the processing of the Personal Data.",
      },
      {
        id: 4,
        text: "whether FastaMoni has another lawful basis for retaining that information beyond the period for which it is necessary to serve the original purpose.",
      },
    ],
  },
  {
    id: 12,
    title: "CONTACT DETAILS",
    text: "If you have any question, request or complaints, or you require further information not already provided in this Privacy Policy, please contact us by sending an email to support@fastamoni.com.\n \nWe maintain a data breach procedure in order to deal with incidents concerning Personal Information or practices leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Personal Information transmitted, stored or otherwise processed. You may contact our DPO upon becoming aware of any breach of Personal Information or if your access credentials have been compromised, to enable us to take the necessary steps towards ensuring the security of your Personal Information or account.",
  },
  {
    id: 13,
    title: "UPDATES TO THIS POLICY",
    text: "We reserve the right to periodically update and modify the terms of our Privacy Policy without notice. Changes to this Privacy Policy will take effect on the last updated date. ",
  },
];

export const aboutInfo = [
  {
    id: 1,
    icon: RiSecurePaymentFill,
    title: "Ease of Payment",
    desc: "Digitalized process of making transactions faster and more flexible by reducing wait time and eliminate customer hassles.",
  },
  {
    id: 2,
    icon: HiCubeTransparent,
    title: "Transparency",
    desc: "Secured processing of transactions from initiation to approval is seamless and accessible.",
  },
  {
    id: 3,
    icon: BsPiggyBankFill,
    title: "Financial Inclusion",
    desc: "Increasing access to and usage of financial services to the unbanked and the under-banked population.",
  },
];

export const logos = [stack, flutterwaveLogo, aws, bspLogo, lenco, cloudLogo];

export const learnMoreInfo = [
  {
    title: "Flexible fee payment options",
    img: brainImg,
    link: "/",
  },
  {
    title: "Effortless payment management",
    img: effortImg,
    link: "/",
  },
  {
    title: "Sub-account management",
    img: manageIcon,
    link: "/",
  },
];

export const bizflexUsage = [
  {
    title: "Freelancers",
    sub: "No More Financial Stress for Freelancers",
    img: brainImg,
    link: "/",
    points: [
      "Say goodbye to irregular income. BizFlex offers financial stability and growth opportunities for freelancers.",
      "Juggling projects and payments? BizFlex helps you manage income, expenses, and save for the future.",
    ],
  },
  {
    title: "SME’s",
    sub: "Financial Solutions for Nigerian SMEs",
    img: effortImg,
    link: "/",
    points: [
      "BizFlex is tailored for SMEs like yours. Access tools to manage cash flow, expenses, and payroll.",
      "BizFlex provides the financial foundation for your SME`s growth Make informed decisions with real-time data.",
    ],
  },
  {
    title: "General Audience",
    sub: "BizFlex: Your Financial Partner",
    img: manageIcon,
    link: "/",
    points: [
      "Whether you are a freelancer or an SME, BizFlex offers financial solutions to help you succeed.",
      "Take control of your finances and achieve your business goals with BizFlex.",
    ],
  },
];

export const bizflexFeatures = [
  {
    id: 1,
    text: "Easy business registration",
  },
  {
    id: 2,
    text: "Comprehensive account management",
  },
  {
    id: 3,
    text: "Streamlined invoice payments and bulk transfers",
  },
  {
    id: 4,
    text: "Virtual Card and financial flexibility",
  },
];

export const socialIcons = [
  {
    link: "https://facebook.com/fastamoni",
    icon: RiFacebookFill,
  },
  {
    link: "https://twitter.com/fastamoni",
    icon: RiTwitterXLine,
  },
  {
    link: "https://www.instagram.com/fastamonihq",
    icon: RiInstagramLine,
  },
  {
    link: "https://www.linkedin.com/company/fastamoni/",
    icon: FaLinkedinIn,
  },
];
