import {
  Box,
  Flex,
  HStack,
  Button,
  useMediaQuery,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { Logo } from "../../assets/exports";
import SideDrawer from "./SideDrawer";
import { activeStyle, header } from "../common/constants";
import { motion } from "framer-motion";
import { RiArrowDownSLine } from "react-icons/ri";

const Header = () => {
  const [showDrawerMenu, setShowDrawerMenu] = useState(false);
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const toggleSidebar = () => {
    setShowDrawerMenu(true);
  };
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  return (
    <Box
      bg={scroll ? "rgba(255, 255, 255, 0.15)" : "gradient"}
      backdropFilter={"blur(10px)"}
      zIndex="5"
      pos="fixed"
      mt="-3"
      pt={2}
      minH={isMobile ? "8vh" : "10vh"}
      pr={!isMobile ? "90px" : "20px"}
      pl={!isMobile ? "70px" : "0"}
      w="100%"
    >
      <SideDrawer
        isOpen={showDrawerMenu}
        onClose={() => setShowDrawerMenu(false)}
      />
      {!isMobile ? (
        <Flex py="25px" w="full" align="center" justify="space-between">
          <Image
            src={Logo}
            w="180px"
            h="42px"
            onClick={() => navigate("/home")}
            cursor="pointer"
          />
          <HStack justify="center" gap="2rem" mr={2}>
            {header?.map((item, i) => (
              <Box className="dropdown" key={i}>
                {item.path ? (
                  <NavLink
                    to={item.path}
                    className="nav"
                    style={({ isActive }) =>
                      isActive
                        ? activeStyle
                        : {
                            ...activeStyle,
                            color: "",
                            fontWeight: "400",
                          }
                    }
                  >
                    {item.name}
                  </NavLink>
                ) : (
                  <Box
                    cursor="pointer"
                    fontSize="16px"
                    key={i}
                    paddingLeft="17px"
                  >
                    <Flex alignItems="center" gap="6px">
                      {item.name} <RiArrowDownSLine display="inline" />
                    </Flex>

                    {item.sub && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        className="dropdown-content"
                        whileInView={{ y: [6, 0], opacity: 1 }}
                      >
                        {item.sub?.map((data, i) => (
                          <Box className="dropdown-text">
                            <Link
                              target="_blank"
                              _hover={{ textDecor: "unset" }}
                              href={data.path}
                              key={i}
                              isExternal
                            >
                              {data.name}
                            </Link>
                          </Box>
                        ))}
                      </motion.div>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </HStack>
          <HStack justify="center" gap="8px">
            <Button w="fit-content" px="34px" h="45px">
              Explore
            </Button>
          </HStack>
        </Flex>
      ) : (
        <Flex justifyContent="space-between" align="center" m="3">
          <Image src={Logo} w="205px" h="52px" />
          <MdMenu
            color="mainBg"
            size={28}
            cursor="pointer"
            onClick={toggleSidebar}
          />
        </Flex>
      )}
    </Box>
  );
};

export default Header;
