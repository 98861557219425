import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { HOME, CONTACT, BLOG, FEATURE, PP, TC, FAQ, ABOUT } = PATHS;

const Home = WithSuspense(lazy(() => import("../pages/Home/Home")));
const Features = WithSuspense(lazy(() => import("../pages/Features/Features")));
const About = WithSuspense(lazy(() => import("../pages/About/About")));
const Blog = WithSuspense(lazy(() => import("../pages/Blog/Blog")));
const Faq = WithSuspense(lazy(() => import("../pages/FAQ/FAQ")));
const Contact = WithSuspense(lazy(() => import("../pages/Contact/Contact")));
const Pp = WithSuspense(
  lazy(() => import("../pages/PrivacyPolicy/PrivacyPolicy"))
);
const Tc = WithSuspense(lazy(() => import("../pages/TnC/TnC")));

export const ROUTES = [
  { path: HOME, element: <Home /> },
  { path: ABOUT, element: <About /> },
  { path: FEATURE, element: <Features /> },
  { path: BLOG, element: <Blog /> },
  { path: CONTACT, element: <Contact /> },
  { path: FAQ, element: <Faq /> },
  { path: TC, element: <Tc /> },
  { path: PP, element: <Pp /> },
  { path: "*", element: <Navigate to="/" replace /> },
];
