import React from 'react';
import {
  Box,
  Flex,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
  Grid,
} from '@chakra-ui/react';

export const FooterGrid = ({ data, title }) => {
  return (
    <GridItem colSpan={[1, 1, 1, 1]}>
      <Grid
        placeItems="center"
        bgColor="#7B47CC"
        color="#ffff"
        borderRadius="6px"
        w="fit-content"
        px="16px"
        h="40px"
        mx={{ base: 'auto', md: 'unset' }}
      >
        <Heading fontSize="16px">{title}</Heading>
      </Grid>

      {data?.map((item, i) => (
        <Box mt="15px" key={i} textAlign={{ base: 'center', md: 'left' }}>
          <Link fontWeight={500} href={item.path}>
            {item.name}
          </Link>
        </Box>
      ))}
    </GridItem>
  );
};

export const AddGrid = ({ data }) => {
  return (
    <GridItem colSpan={[1, 1, 1, 1]}>
      {data?.map((item, i) => (
        <Box lineHeight="2em" mb="20px" key={i}>
          <Flex align="center" gap=".5rem">
            <Image src={item.flag} w="1rem" h=".5rem" />
            <Heading fontSize="16px">{item.location}</Heading>
          </Flex>
          <Text mt="8px" fontSize="13px" fontWeight={500}>
            FastaMoni Technologies Inc.
          </Text>
          <Text fontSize="13px" fontWeight={500}>
            {item.add}
          </Text>
        </Box>
      ))}
    </GridItem>
  );
};
