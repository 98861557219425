import React from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";
import Header from "./Header";
import Footer from "./Footer";

export const Layout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 991px)");

  return (
    <Box
      color="color"
      className="layout"
      bg="#fff"
      position="relative"
      overflowX="hidden"
    >
      <Box minH="100vh" overflowX="hidden">
        <Header data={isMobile} />
        <Box
          w="100%"
          // maxW={"1326px"}
          pt={!isMobile ? "1.5rem" : ""}
          minH="100vh"
          pl={!isMobile ? "100px" : "20px"}
          pr={!isMobile ? "100px" : "20px"}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};
