export const PATHS = {
  HOME: "/",
  FEATURE: "/features",
  ABOUT: "/about",
  CONTACT: "/contact",
  BLOG: "/blog",
  FAQ: "/faq",
  PP: "/privacy-policy",
  TC: "/t&c",
};
