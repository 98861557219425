import React, { useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Box,
  Flex,
  Image,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";
import { activeStyle, header, socialIcons } from "../common/constants";
import { NavLink } from "react-router-dom";
import { fastamoniLogo } from "../../assets/exports";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const SideDrawer = ({ isOpen, onClose }) => {
  const [show, setShow] = useState("");

  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent color="color" zIndex="999" pos="relative" bgColor="#fff">
          <DrawerBody p={0}>
            <Box
              h="3px"
              background="linear-gradient(90deg, #FF9D16, #2463EB)"
            ></Box>
            <Box
              h={"100vh"}
              w="100%"
              overflowY="hidden"
              transition="0.3s all ease-in-out"
            >
              <Flex
                alignItems="center"
                mt="27px"
                justifyContent="space-between"
                px="22px"
              >
                <Image src={fastamoniLogo} />
                <IoClose fontSize="24px" cursor="pointer" onClick={onClose} />
              </Flex>

              <Box pt="1rem">
                {header.map((item, i) => (
                  <Flex key={i} flexDir="column" lineHeight="3em">
                    {item.path ? (
                      <NavLink
                        to={item.path}
                        cursor="pointer"
                        className="nav"
                        onClick={() => {
                          onClose();
                          setShow(false);
                        }}
                        style={({ isActive }) =>
                          isActive
                            ? activeStyle
                            : {
                                ...activeStyle,
                                color: "",
                              }
                        }
                      >
                        {item.name}
                      </NavLink>
                    ) : (
                      <Flex
                        key={i}
                        flexDir="column"
                        onClick={() => setShow((prev) => !prev)}
                        cursor="pointer"
                        px="20px"
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          fontWeight={500}
                        >
                          {" "}
                          {item.name}{" "}
                          {show ? <IoIosArrowForward /> : <IoIosArrowDown />}
                        </Flex>

                        {show && (
                          <Flex
                            flexDir="column"
                            lineHeight="3em"
                            fontSize="12px"
                          >
                            {item.sub?.map((data, i) => (
                              <Link
                                target="_blank"
                                _hover={{ textDecor: "unset" }}
                                href={data.path}
                                key={i}
                                isExternal
                              >
                                <Text
                                  borderBottom="1px solid #7B47CC"
                                  className="sidedrop-text"
                                  key={i}
                                >
                                  {data.name}
                                </Text>
                              </Link>
                            ))}
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
              <Flex
                align="center"
                gap="1rem"
                justifyContent="center"
                mx="20px"
                borderTop="1px"
                borderColor="#FF9D16"
                mt="100px"
                pt="49px"
                borderRadius="3px"
              >
                {socialIcons?.map((data) => {
                  return (
                    <Box
                      border="1px solid #EFEFEF"
                      borderRadius="3px"
                      px="15px"
                      py="12px"
                    >
                      <Link href={data.link} target="_blank">
                        <Image as={data?.icon} />
                      </Link>
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideDrawer;
